















































































import { Component, Vue } from 'vue-property-decorator';
import { APP_NAME, CLIENT_ID, FALLBACK_PROVIDER, NETWORK } from '@/common/constants';

@Component
export default class HomeHeader extends Vue {
  appName = APP_NAME;
  network = NETWORK;
  fallbackProvider = FALLBACK_PROVIDER;
  clientId = CLIENT_ID;
  mobileOpen = false;
  isMobile = false;

  created () : void {
    window.addEventListener('resize', this.onResize);
    // resize the title-g-l element
    if (window.innerWidth <= 825) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  destroyed () : void {
    window.removeEventListener('resize', this.onResize);
  }

  onResize (event: Event) : void {
    const target = event.target as Window;
    // resize the title-g-l element
    if (target.innerWidth <= 825) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  toggleMobile () : void {
    this.mobileOpen = !this.mobileOpen;
  }
}
