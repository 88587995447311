import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import scroll from '@/store/modules/scroll';

Vue.use(Vuex);

const { store, rootActionContext, moduleActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    scroll
  }
});

export default store;

// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext };

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
