import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import Collectible from '@manifoldxyz/collectible-widget';
import manifoldCSSReset from '@manifoldxyz/css-reset';
import MConnection from '@manifoldxyz/oauth-connect-widget';
import 'tailwindcss/dist/tailwind.css';
import store from '@/store/store';
import App from './App.vue';
import { FALLBACK_PROVIDER, NETWORK } from './common/constants';
import router from './router';

manifoldCSSReset();

const options: any = {
  network: NETWORK,
  fallbackProvider: FALLBACK_PROVIDER
};

Collectible.Countdown.install(Vue);
Collectible.Buy.install(Vue);
Collectible.Inventory.install(Vue);

Vue.use(MConnection.MultiWalletConnect, options);
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

new Vue({
  router,
  // NOTE: store.direct is the typed version we reference elsewhere
  store: store.original,
  render: h => h(App)
}).$mount('#app');
