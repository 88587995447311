var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "hero" } },
    [
      _c("div", { staticClass: "bg", style: _vm.parallax() }),
      _c("img", { attrs: { src: require("@/assets/images/footer-logo.svg") } }),
      _c("home-header"),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "hero-text" },
        [
          _vm._m(1),
          _vm._m(2),
          _c("m-buy", {
            attrs: {
              id: "hero-mint",
              mint: true,
              "app-name": _vm.appName,
              "fallback-provider": _vm.fallbackProvider,
              network: _vm.network,
              address: _vm.contractAddress,
              "client-id": _vm.clientId
            }
          }),
          _c("h3", [
            _vm.showPresaleStartTime
              ? _c("span", [
                  _vm._v("Presale Starts at 5:00PM EDT, August 31st"),
                  _c("br")
                ])
              : _vm._e(),
            _vm._v(_vm._s(_vm.mintCountdownText))
          ]),
          _c(
            "div",
            { staticClass: "countdown" },
            [
              _c("m-countdown", {
                attrs: {
                  "app-name": _vm.appName,
                  "fallback-provider": _vm.fallbackProvider,
                  network: _vm.network,
                  address: _vm.contractAddress,
                  "client-id": _vm.clientId
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._m(3)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottle-shadow" }, [
      _c("img", {
        staticClass: "bottle",
        attrs: { src: require("@/assets/images/bottle.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _vm._v("Kevin Abosch "),
      _c("span", { staticClass: "separator" }, [_vm._v("::")]),
      _vm._v(" Dassai")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("an NFT artwork collaboration "),
      _c("span", { staticClass: "separator" }, [_vm._v("::")]),
      _vm._v(" AUGUST 31st 2022")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-area" }, [
      _c("p", { staticClass: "left-text" }, [
        _vm._v(
          "Celebrating a crossroads of both tradition and technology. Irish contemporary artist Kevin Abosch has partnered with CreativeWorkStudios and premium Japanese sake producer AsahiShuzo for a unique collaboration. In a series NFT artworks, collectors will be given an opportunity to claim bottles of a limited, exclusive and premium Dassai product."
        )
      ]),
      _c("div", { staticClass: "spacer" }),
      _c("p", { staticClass: "right-text" }, [
        _vm._v(
          "The collaboration will see Kevin Abosch create a collection of 777 unique and original 1 of 1 digital artworks, the result of the artist’s engagement with millions of data-points related to both the Dassai sake brewing process and Asahi Shuzo’s values. Inspired by Abosch’s art, AsahiShuzo will in turn create a limited, corresponding run of this specially brewed premium sake. Previous limited editions from the brand have been highly sought after, one bottle fetching 843,750 yen ($8,026) at auction in Hong Kong."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "Embracing innovation, for this initiative AsahiShuzo have combined the highest level of traditional craft with detailed technological insights, enabling them to brew sake of unprecedented refinement. By using complex, precision data, in tandem with the extraordinary skill and experience of their workforce, they manage and monitor the development of their sake at every stage of the brewing process to create a truly best-in-class product."
        ),
        _c("br"),
        _c("br"),
        _c("b", [
          _vm._v(
            "Artworks holders will have a window in which to claim these limited-edition physical bottles that will, in turn, be shipped to collectors worldwide."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }