








import { Component, Vue } from 'vue-property-decorator';
import { setProviderEventsHandler } from '@manifoldxyz/manifold-dropsitetools-lib';
import store from '@/store/store';

@Component
export default class App extends Vue {
  created () : void {
    setProviderEventsHandler(this.handleConnection);
  }

  async handleConnection () : Promise<void> {
    console.log('connected');
  }

  handleScroll (event: Event) : void {
    store.commit.scroll.SET_PAGE_Y_OFFSET((event.target as any).scrollTop);
  }
}
