var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/images/header-logo.svg") }
      }),
      _c(
        "div",
        { staticClass: "socials" },
        [
          _vm._m(0),
          _vm._m(1),
          _c("m-multi-wallet-connect", {
            staticClass: "connect-button",
            attrs: {
              "app-name": _vm.appName,
              network: _vm.network,
              "client-id": _vm.clientId,
              "fallback-provider": _vm.fallbackProvider,
              "delay-auth": true,
              avatar: "",
              "auto-reconnect": false,
              "show-chain": true,
              "grant-type": "signature"
            }
          }),
          _c(
            "a",
            {
              staticClass: "register-button",
              attrs: { target: "_blank", href: "https://redeem.sublimata.xyz" }
            },
            [_vm._v("Redeem Bottle")]
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "segue" } }, [
        _vm.mobileOpen && _vm.isMobile
          ? _c(
              "div",
              { staticClass: "mobile-menu" },
              [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: require("@/assets/images/header-logo.svg") }
                }),
                _c("div", { staticClass: "socials-mobile" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://twitter.com/nftcws"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "social mobile",
                        attrs: { src: require("@/assets/images/twitter.svg") }
                      })
                    ]
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://discord.gg/UHXzs2rg"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "social mobile",
                        attrs: { src: require("@/assets/images/discord.svg") }
                      })
                    ]
                  )
                ]),
                _c("m-multi-wallet-connect", {
                  staticClass: "connect-button mobile-connect",
                  attrs: {
                    "app-name": _vm.appName,
                    network: _vm.network,
                    "client-id": _vm.clientId,
                    "fallback-provider": _vm.fallbackProvider,
                    "delay-auth": true,
                    avatar: "",
                    "auto-reconnect": false,
                    "show-chain": true,
                    "grant-type": "signature"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.isMobile
        ? _c(
            "button",
            {
              attrs: { id: "header-mobile-toggle" },
              on: { click: _vm.toggleMobile }
            },
            [
              !_vm.mobileOpen
                ? _c("img", {
                    attrs: { src: require("@/assets/images/mobile-open.svg") }
                  })
                : _c("img", {
                    attrs: { src: require("@/assets/images/mobile-close.svg") }
                  })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { target: "_blank", href: "https://twitter.com/nftcws" } },
      [
        _c("img", {
          staticClass: "social",
          attrs: { src: require("@/assets/images/twitter.svg") }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { target: "_blank", href: "https://discord.gg/wzttnXbNsA" } },
      [
        _c("img", {
          staticClass: "social",
          attrs: { src: require("@/assets/images/discord.svg") }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }