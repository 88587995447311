















import { Component, Prop, Vue } from 'vue-property-decorator';
import store from '@/store/store';

@Component
export default class DoubleSidedSection extends Vue {
  @Prop({
    type: String,
    required: false,
    default: false
  }) image1: string

  @Prop({
    type: String,
    required: false,
    default: false
  }) image2: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) flipped: boolean

  @Prop({
    type: Number,
    required: false,
    default: 0
  }) offset: number

  windowWidth = window.innerWidth;

  created () : void {
    window.addEventListener('resize', this.onWidthChange);
  }

  destroyed () : void {
    window.addEventListener('resize', this.onWidthChange);
  }

  onWidthChange () : void {
    this.windowWidth = window.innerWidth;
  }

  parallax1 () : string {
    return `transform: translateY(${(store.getters.scroll.pageYOffset - (this.offset * this.scaleFactor())) / -10}px);`;
  }

  parallax2 () : string {
    return `transform: translateY(${(store.getters.scroll.pageYOffset - (this.offset * this.scaleFactor())) / -5}px);`;
  }

  scaleFactor () : number {
    return this.windowWidth > 1000 ? 1 : 1 + (this.windowWidth / 1000 / 3);
  }
}
