export interface ScrollState {
  pageYOffset: number
}

export default {
  namespaced: true as const,

  state: {
    pageYOffset: 0
  } as ScrollState,

  getters: {
    pageYOffset: (state: ScrollState) : number => {
      return state.pageYOffset;
    }
  },

  mutations: {
    SET_PAGE_Y_OFFSET (state: ScrollState, offset: number) : void {
      state.pageYOffset = offset;
    }
  },

  actions: {
    async changeOffset (context: { commit: any }, payload: { offset: number }) : Promise<ScrollState> {
      context.commit('SET_PAGE_Y_OFFSET', payload.offset);
      return {
        pageYOffset: payload.offset
      };
    }
  }
};
