var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("hero"),
      _c("double-sided-section", { attrs: { reverse: true } }, [
        _c(
          "div",
          { staticClass: "section-wrapper" },
          [
            _c("div", { staticClass: "square-outline" }),
            _c("collage", {
              attrs: {
                offset: _vm.windowWidth > 1000 ? 2000 : 1700,
                image1: require("@/assets/images/bar.png"),
                image2: require("@/assets/images/sublimata.png")
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "section-wrapper text-section" }, [
          _c("h2", [_vm._v("ALLOWLIST :: Reserved NFT Mint :: 1.25 ETH")]),
          _c("h3", [
            _c("b", [_vm._v("August 31st - 11am EDT/4pm UK/12am Japan ")])
          ]),
          _c("h3", [
            _c("b", [
              _vm._v(
                "CWS Patron Token Holders & Kevin Abosch 1111 Collection Holders Only"
              )
            ])
          ]),
          _c("h2", [
            _c("br"),
            _vm._v("PRE-SALE :: Mint before Public :: 1.25 ETH")
          ]),
          _c("h3", [
            _c("b", [_vm._v("August 31st - 5pm EDT/10pm UK/6am Japan ")])
          ]),
          _c("h3", [
            _vm._v("[Mint spots granted on a first come first served basis]"),
            _c("br"),
            _c("b", [
              _vm._v(
                "CWS Artist + Collector Token Holders & Pre-Registered Wallets"
              )
            ])
          ]),
          _c("h2", [
            _c("br"),
            _vm._v("PUBLIC SALE :: Open to Public :: 1.5 ETH")
          ]),
          _c("h3", [
            _c("b", [
              _vm._v("September 1st - 5pm EDT/10pm UK/6am Japan onwards")
            ])
          ]),
          _c("h3", [
            _vm._v(
              "[Remaining works granted on a first come first served basis]"
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "video" },
        [
          _c("img", {
            staticClass: "video",
            attrs: { src: require("@/assets/images/video1.png") },
            on: { click: _vm.startProjectVideo }
          }),
          _c("div", { staticClass: "wrapper" }, [
            _c(
              "button",
              {
                staticClass: "play-button",
                on: { click: _vm.startProjectVideo }
              },
              [_vm._v("▶")]
            ),
            _c("span", { staticClass: "caption" }, [_vm._v("WATCH THE VIDEO")])
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showProjectVideo,
                    expression: "showProjectVideo"
                  },
                  {
                    name: "observe-visibility",
                    rawName: "v-observe-visibility",
                    value: function(isVisible, entry) {
                      if (!isVisible && _vm.showProjectVideo) {
                        _vm.projectVideoPlayer.pause()
                      }
                    },
                    expression:
                      "(isVisible, entry) => {\n          if (!isVisible && showProjectVideo) {\n            projectVideoPlayer.pause();\n          }\n        }"
                  }
                ],
                staticClass: "video-wrapper"
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "56.25% 0 0 0",
                      position: "relative",
                      "z-index": "1"
                    }
                  },
                  [
                    _c("iframe", {
                      staticStyle: {
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%"
                      },
                      attrs: {
                        id: "project-video",
                        src:
                          "https://player.vimeo.com/video/715982430?h=f20615c01f&color=ffffff&controls=1&byline=0&portrait=0",
                        frameborder: "0",
                        allow: "autoplay; fullscreen; picture-in-picture",
                        allowfullscreen: ""
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ],
        1
      ),
      _c("double-sided-section", [
        _c(
          "div",
          { staticClass: "section-wrapper" },
          [
            _c("collage", {
              attrs: {
                offset: _vm.windowWidth > 1000 ? 3500 : 3500,
                flipped: true,
                image1: require("@/assets/images/abosch.png"),
                image2: require("@/assets/images/BLUEMARINE.png")
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "section-wrapper text-section" }, [
          _c("h1", [_vm._v("About the Artist :: Kevin Abosch")]),
          _c("p", [
            _vm._v(
              "Kevin Abosch, born in 1969 in Los Angeles, is an Irish conceptual artist and pioneer in cryptoart known for his works in photography, blockchain, sculpture, installation, AI and film. Abosch's work addresses the nature of identity and value by posing ontological questions and responding to sociological dilemmas."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "His work has been exhibited throughout the world, often in civic spaces, including The Hermitage Museum, St Petersburg, The National Museum of China, The National Gallery of Ireland, Jeu de Paume ( Paris), The Irish Museum of Modern Art, The Museum of Contemporary Art Vojvodina, The Bogotá Museum of Modern Art, ZKM (Zentrum für Kunst und Medien) and Dublin Airport."
            )
          ]),
          _c(
            "a",
            {
              attrs: { target: "_blank", href: "https://www.kevinabosch.com" }
            },
            [_vm._v("KEVIN ABOSCH STUDIO")]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "video" },
        [
          _c("img", {
            staticClass: "video",
            attrs: { src: require("@/assets/images/video2.png") },
            on: { click: _vm.startDassaiVideo }
          }),
          _c("div", { staticClass: "wrapper" }, [
            _c(
              "button",
              {
                staticClass: "play-button",
                on: { click: _vm.startDassaiVideo }
              },
              [_vm._v("▶")]
            ),
            _c("span", { staticClass: "caption" }, [
              _vm._v("WATCH: THE DASSAI STORY")
            ])
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDassaiVideo,
                    expression: "showDassaiVideo"
                  },
                  {
                    name: "observe-visibility",
                    rawName: "v-observe-visibility",
                    value: function(isVisible, entry) {
                      if (!isVisible && _vm.showDassaiVideo) {
                        _vm.dassaiVideoPlayer.pause()
                      }
                    },
                    expression:
                      "(isVisible, entry) => {\n          if (!isVisible && showDassaiVideo) {\n            dassaiVideoPlayer.pause();\n          }\n        }"
                  }
                ],
                staticClass: "video-wrapper"
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "56.25% 0 0 0",
                      position: "relative",
                      "z-index": "1"
                    }
                  },
                  [
                    _c("iframe", {
                      staticStyle: {
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%"
                      },
                      attrs: {
                        id: "dassai-video",
                        src:
                          "https://player.vimeo.com/video/716037196?h=f20615c01f&color=ffffff&controls=1&byline=0&portrait=0",
                        frameborder: "0",
                        allow: "autoplay; fullscreen; picture-in-picture",
                        allowfullscreen: ""
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ],
        1
      ),
      _c("double-sided-section", { attrs: { reverse: true } }, [
        _c(
          "div",
          { staticClass: "section-wrapper" },
          [
            _c("div", { staticClass: "square-outline variant" }),
            _c("collage", {
              attrs: {
                offset: _vm.windowWidth > 1000 ? 5250 : 4750,
                image1: require("@/assets/images/glass.png"),
                image2: require("@/assets/images/grains.png")
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "section-wrapper text-section" }, [
          _c("h1", { staticStyle: { flex: "1" } }, [_vm._v("DASSAI SAKE")]),
          _c("p", [
            _vm._v(
              "DASSAI is a series of sake produced by Asahi Shuzo, a brewery based in Yamaguchi Prefecture in the south of Honshu, Japan’s main island."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "The name 'DASSAI' reflects the ancient name for this region of Japan. The brewery’s philosophy is founded on avoiding complacency to ensure that existing techniques are constantly reformed and revolutionised. Asahi Shuzo pride themselves on brewing sake year-round - something often unheard of in the sake industry - using only the finest quality Yamada Nishiki rice across their sake range."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "Dassai is made using a careful combination of tradition and cutting-edge technology. At Asahi Shuzo, they have come to use what works and have left behind what does not, choosing what their experience dictates is best from both the old world and the new."
            )
          ]),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www.asahishuzo.ne.jp/en"
              }
            },
            [_vm._v("DASSAI WEBSITE")]
          ),
          _c("p", { staticClass: "sub-text" }, [
            _vm._v(
              "GOVERNMENT WARNING: (1) According to the Surgeon General, women should not drink alcoholic beverages during pregnancy because of the risk of birth defects. (2) Consumption of alcoholic beverages impairs your ability to drive a car or operate machinery, and may cause health problems. "
            )
          ])
        ])
      ]),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "left" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/footer-logo.svg") }
        }),
        _c("h1", [
          _vm._v("COPYRIGHT / コピーライト"),
          _c("br"),
          _vm._v("© Copyright 2017/22 - Creative Work Studios"),
          _c("br"),
          _c("br")
        ]),
        _c("p", [
          _vm._v(
            "All rights reserved. © for all text and image, use only through contractual agreement. No portion of this site may be copied, reused or produced. Nor may it be distributed, published or used in any other way in analogue or digital form without prior written agreement."
          )
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c("h1", [
          _vm._v("CORPORATE / 企業情報"),
          _c("br"),
          _vm._v("Creative Work Studios Limited"),
          _c("br"),
          _c("br")
        ]),
        _c("p", [
          _vm._v("info [at] creativeworkstudios.com"),
          _c("br"),
          _vm._v("UK Reg.: 10884509 VAT: 274 9151 81")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }