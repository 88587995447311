








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DoubleSidedSection extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) reverse: boolean
}
