


































































































































import Player from '@vimeo/player';
import { Component, Vue } from 'vue-property-decorator';
import { EthereumNetwork, EthereumProvider } from '@manifoldxyz/manifold-sdk';
import { FALLBACK_PROVIDER, NETWORK } from '@/common/constants';
import Collage from '@/components/Collage.vue';
import DoubleSidedSection from '@/components/DoubleSidedSection.vue';
import Hero from '@/components/Hero.vue';

@Component({
  components: {
    Collage,
    DoubleSidedSection,
    Hero
  }
})
export default class Home extends Vue {
  creatorContract: any;
  extensionContract: any;
  account: string | undefined = ''
  shortenedAccount = ''
  tab = 0;
  windowWidth = window.innerWidth;
  projectVideoPlayer: any;
  dassaiVideoPlayer: any;
  showProjectVideo = false;
  showDassaiVideo = false;

  fallbackProvider = FALLBACK_PROVIDER;
  network = NETWORK;

  async mounted () : Promise<void> {
    // m-connect event
    window.addEventListener(EthereumProvider.ADDRESS_CHANGED, async () => {
      this.account = EthereumProvider.selectedAddress();
      if (this.account) {
        this.shortenedAccount = `${this.account.substring(0, 3)}...${this.account.substring(this.account.length - 4, this.account.length)}`;
      } else {
        localStorage.removeItem('connectedAddress');
        this.account = '';
        this.shortenedAccount = '';
      }
    });

    EthereumProvider.initialize(NETWORK as any as EthereumNetwork);
    const prevConnectAddress = localStorage.getItem('connectedAddress');

    if (prevConnectAddress) {
      this.account = prevConnectAddress;
      this.shortenedAccount = `${this.account.substring(0, 3)}...${this.account.substring(this.account.length - 4, this.account.length)}`;
    }

    const projectVideo = document.querySelector('#project-video');
    const dassaiVideo = document.querySelector('#dassai-video');
    this.projectVideoPlayer = new Player(projectVideo);
    this.dassaiVideoPlayer = new Player(dassaiVideo);
  }

  async created () : Promise<void> {
    window.addEventListener('resize', this.onWidthChange);
    if (NETWORK) {
      this.network = NETWORK;
    }
  }

  destroyed () : void {
    window.removeEventListener('resize', this.onWidthChange);
  }

  onWidthChange () : void {
    this.windowWidth = window.innerWidth;
  }

  startProjectVideo () : void {
    this.showProjectVideo = true;
    this.projectVideoPlayer.play();
    this.projectVideoPlayer.setVolume(1);
  }

  startDassaiVideo () : void {
    this.showDassaiVideo = true;
    this.dassaiVideoPlayer.play();
    this.dassaiVideoPlayer.setVolume(1);
  }

  openRegistration () : void {
    window.open('https://www.creativeworkstudios.com/karegistration', '_blank');
  }
}
