









































import { Component, Vue } from 'vue-property-decorator';
import { APP_NAME, CLIENT_ID, CONTRACT_ADDRESS, FALLBACK_PROVIDER, NETWORK } from '@/common/constants';
import HomeHeader from '@/components/HomeHeader.vue';
import store from '@/store/store';

@Component({
  components: {
    HomeHeader
  }
})
export default class Hero extends Vue {
  appName = APP_NAME;
  fallbackProvider = FALLBACK_PROVIDER;
  contractAddress = CONTRACT_ADDRESS ?? '';
  network = NETWORK;
  clientId = CLIENT_ID;
  now: Date = new Date();

  async mounted (): Promise<void> {
    setInterval(() => {
      this.now = new Date((window as any).mStore.state[this.contractAddress].now);
    }, 1000);
  }

  parallax () : string {
    return `background-position: center ${(store.getters.scroll.pageYOffset) / 2}px;`;
  }

  get saleHasStarted () : boolean {
    return (window as any).mStore.state[this.contractAddress]
      ? (window as any).mStore.state[this.contractAddress].startTime <= this.now
      : false;
  }

  get saleHasEnded () : boolean {
    return (window as any).mStore.state[this.contractAddress]
      ? (window as any).mStore.state[this.contractAddress].endTime < this.now || (window as any).mStore.state[this.contractAddress].tokenRemaining === 0
      : false;
  }

  get presaleHasEnded () : boolean {
    return (window as any).mStore.state[this.contractAddress]
      ? (window as any).mStore.state[this.contractAddress].presaleEndTime <= this.now
      : false;
  }

  get showPresaleStartTime () : boolean {
    return this.saleHasStarted && this.now < new Date('August 31, 2022 21:00:00Z');
  }

  get mintCountdownText () : string {
    if (this.saleHasStarted && !this.saleHasEnded) {
      if (this.presaleHasEnded) {
        return 'Sale Ends';
      } else {
        if (this.now < new Date('August 31, 2022 21:00:00Z')) {
          return 'Allowlist Ends';
        }
        return 'Presale Ends';
      }
    } else if (!this.saleHasStarted) {
      return 'Presale Starts';
    }
    return '';
  }
}
