import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter);
}

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

/**
 * Route Navigation Guards
 *
 * Ref: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
 */
// router.beforeEach((to, from, next) => {
//   const loggedIn = store.jwt && store.walletAddress
//   if (loggedIn && to.path === '/') {
//     return next()
//   } else if ((!loggedIn) && to.path !== '/') {
//     return next()
//   } else {
//     return next()
//   }
// })

export default router;
