var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("img", {
      staticClass: "image1",
      style: (_vm.flipped ? "left: 0;" : "right: 0;") + _vm.parallax1(),
      attrs: { "v-if": _vm.image1, src: _vm.image1 }
    }),
    _c("img", {
      staticClass: "image2",
      style: (_vm.flipped ? "right: 0;" : "left: 0;") + _vm.parallax2(),
      attrs: { "v-if": _vm.image2, src: _vm.image2 }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }